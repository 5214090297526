import React, { Component } from "react";
import { FormGroup, Label, Input } from "reactstrap";
class ChooseBank extends Component {
   
    render() {
        return (
            <div
                className={
                    this.props.primary ? "container-bg mb-3" : this.props.defaultChecked ? "container-border-pm mb-3" : "container-border mb-3"
                }>
                <div className="row">
                    <FormGroup check className="col-12">
                        <Label check className="col-12">
                            <div className="custom-radio-bank">
                                <Input
                                    type="radio"
                                    name="radio1"
                                    disabled={this.props.isDisable}
                                    className={`bullet-radio ${this.props.isDisable && 'disabled'}`}
                                    checked={this.props.defaultChecked}
                                    hidden={this.props.primary ? true : false}
                                    onClick={this.props.onChange}
                                    value={this.props.value}
                                />
                            </div>
                            <div className="row pl-3">
                                <div className="col-md-6 col-6">
                                    <div className="pb-1 f-14 font-weight-normal">{this.props.nama_pemilik_rekening}</div>
                                    <div className="font-weight-bold">{this.props.no_rek}</div>
                                    {/* <i className="text-danger">{this.props.pesan}</i> */}
                                </div>
                                <div className="col-6 col-md-6 text-right" style={{ alignSelf: "center" }}>
                                    <img
                                        className="float-right"
                                        style={{ width: "75px" ,filter:this.props.isDisable ?'grayscale(100%)':''}}
                                        src={process.env.PUBLIC_URL + `/images/bank/${this.props.nama_bank}.png`}
                                        alt="bank-img"
                                    />
                                </div>
                            </div>
                        </Label>
                    </FormGroup>
                </div>
            </div>
        );
    }
}

export default ChooseBank;
