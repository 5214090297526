import React, { Component } from "react";
import { connect } from "react-redux";
import { BorrowerId, UpdateDigisignBorrower } from "../../../../redux/action/Borrower";
import { Form, Label, Input, Button } from "reactstrap";
import Swal from "sweetalert2";
import {
    InputIcon,
    Inputs,
    ShowFoto,
    ModalFoto,
    OpenCamera,
    CorrectFotoSelfie,
    ShowBPInformasi,
    ShowBPKontakDarurat
} from "../../../../library/components/Components";
import { CheckDigisignData, imgToFile } from "../../../../library/helper/helper";
import { validate } from "../../../../library/validation/validate";
import { LoadingPersonal } from "../../../assets/loading data/LoadingBorrower";
import Location from "../../../assets/svg/Location"; //icon svg

class DatadiriPersonal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nama_borrower: "",
            email: localStorage.email,
            no_handphone: localStorage.no_hp,
            tempat_lahir: "",
            tgl_lahir: "",
            no_ktp: "",
            no_npwp: "",
            id_jenis_kelamin: "",
            id_agama: "",
            status_perkawinan: "",
            deskripsi_usaha: "",
            alamat: "",
            id_kewarganegaraan: "",
            id_provinsi: "",
            id_kota: "",
            kelurahan: "",
            kecamatan: "",
            kode_pos: "",
            id_pendidikan: "",
            id_pekerjaan: "",
            id_pekerjaan_online: "",
            id_bidang_pekerjaan: "",
            pengalaman_kerja: "",
            pendapatan: "",
            status_kepemilikan_rumah: "",
            modal: false,
            modalktp: false,
            modalselfie: false,
            setModal: false,
            checked: false,
            disabled: true,
            id_sektor: "",
            camera: true,
            fotoDone: false,
            modalselfieWebcam: false,
            update_borrower: "",
            nama_kontak_darurat: "",
            no_handphone_kontak_darurat: "",
            alamat_kontak_darurat: "",
            hubungan_kontak_darurat: "",
            lokasi_pasar: "",
            alamat_domisili: "",
            provinsi_domisili: "",
            kota_domisili: "",
            kelurahan_domisili: "",
            kecamatan_domisili: "",
            kode_pos_domisili: "",
            errEmail: "",
            errNumber: "",
            re_fotoselfie: "",
            grup_penjamin: "",
            perusahaan_penjamin: "",
            samaDomisili: false
        };
        this.fotoLagi = this.fotoLagi.bind(this);
        this.successFoto = this.successFoto.bind(this);
    }

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };
    handleImgSelfie = (e) => {
        let file = e.target.files[0];
        this.setState({
            foto_selfie: file
        });
    };
    togglemodalktp() {
        this.setState({
            modalktp: !this.state.modalktp,
            setModal: !this.state.setModal
        });
    }
    togglemodalselfie() {
        this.setState({
            modalselfie: !this.state.modalselfie,
            setModal: !this.state.setModal
        });
    }
    //
    //button check valid
    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };

    //foto camera
    toggleOpenCamera() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }

    handleTakePhotoAnimationDone(dataUri) {
        this.setState({
            re_fotoselfie: dataUri,
            camera: false
        });
    }
    fotoLagi() {
        this.setState({
            camera: !this.state.camera
        });
    }
    successFoto = () => {
        this.setState({
            modalselfieWebcam: false,
            setModal: false,
            fotoDone: true
        });
    };
    //

    //function button
    handleSaveBorrower = async () => {
        //email , no hp milik user
        const { email, no_handphone, foto_selfie } = this.state;

        const formData = new FormData();
        formData.append("email", email);
        formData.append("no_handphone", no_handphone);
        formData.append("image", imgToFile(foto_selfie));

        await this.props
            .dispatch(UpdateDigisignBorrower(formData))
            .then((val) => {
                return Swal.fire({
                    title: "Update Data",
                    text: "Data anda berhasil di rubah. Data anda akan di validasi kembali",
                    icon: "success",
                    button: "ok"
                }).then(() => {
                    window.location.reload();
                });
            })
            .catch((err) => {
                // console.log(err.response)
                Swal.fire({
                    title: "Gagal Update",
                    text: `${err.response.data.message}`,
                    icon: "warning",
                    button: "ok"
                });
            });
    };

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(BorrowerId(this.props.id));
        this.setState({
            nama_borrower: this.props.borrower.borrowerDetail.nama_borrower,
            no_ktp: this.props.borrower.borrowerDetail.no_ktp,
            no_npwp: this.props.borrower.borrowerDetail.no_npwp,
            alamat: this.props.borrower.borrowerDetail.alamat,
            tempat_lahir: this.props.borrower.borrowerDetail.tempat_lahir,
            tgl_lahir: new Date(this.props.borrower.borrowerDetail.tgl_lahir).toLocaleDateString("IN"),
            deskripsi_usaha: this.props.borrower.borrowerDetail.deskripsi_usaha,
            id_provinsi: this.props.borrower.borrowerDetail.id_provinsi,
            id_kota: this.props.borrower.borrowerDetail.id_kota,
            kelurahan: this.props.borrower.borrowerDetail.kelurahan,
            kecamatan: this.props.borrower.borrowerDetail.kecamatan,
            kode_pos: this.props.borrower.borrowerDetail.kode_pos,
            foto_ktp: this.props.borrower.borrowerDetail.foto_ktp,
            foto_selfie: this.props.borrower.borrowerDetail.foto_selfie,
            total_asset: this.props.borrower.borrowerDetail.total_asset,
            update_borrower: this.props.borrower.borrowerDetail.update_borrower,
            alamat_domisili: this.props.borrower.borrowerDetail.alamat_domisili,
            kelurahan_domisili: this.props.borrower.borrowerDetail.kelurahan_domisili,
            kecamatan_domisili: this.props.borrower.borrowerDetail.kecamatan_domisili,
            kode_pos_domisili: this.props.borrower.borrowerDetail.kode_pos_domisili,

            nama_kontak_darurat: this.props.borrower.borrowerDetail.kontak_darurat.nama,
            no_handphone_kontak_darurat: this.props.borrower.borrowerDetail.kontak_darurat.no_handphone,
            alamat_kontak_darurat: this.props.borrower.borrowerDetail.kontak_darurat.alamat,
            hubungan_kontak_darurat: this.props.borrower.borrowerDetail.kontak_darurat.hubungan,

            lokasi_pasar: this.props.borrower.borrowerDetail.lokasi_pasar,

            id_sektor: this.props.borrower.borrowerDetail.ojkJson.sektor.keterangan,
            id_jenis_kelamin: this.props.borrower.borrowerDetail.ojkJson.jenisKelamin.keterangan,
            id_agama: this.props.borrower.borrowerDetail.ojkJson.agama.keterangan,
            status_perkawinan: this.props.borrower.borrowerDetail.ojkJson.statusPerkawinan.keterangan,
            provinsi: this.props.borrower.borrowerDetail.ojkJson.provinsi.keterangan,
            kota: this.props.borrower.borrowerDetail.ojkJson.kota.keterangan,
            provinsi_domisili: this.props.borrower.borrowerDetail.ojkJson.provinsiDomisili.keterangan,
            kota_domisili: this.props.borrower.borrowerDetail.ojkJson.kotaDomisili?.keterangan,
            id_pendidikan: this.props.borrower.borrowerDetail.ojkJson.pendidikan.keterangan,
            id_pekerjaan: this.props.borrower.borrowerDetail.ojkJson.pekerjaan.keterangan,
            id_pekerjaan_online: this.props.borrower.borrowerDetail.ojkJson.pekerjaanOnline.keterangan,
            id_bidang_pekerjaan: this.props.borrower.borrowerDetail.ojkJson.bidangPekerjaan.keterangan,
            pengalaman_kerja: this.props.borrower.borrowerDetail.ojkJson.pengalamanKerja.keterangan,
            pendapatan: this.props.borrower.borrowerDetail.ojkJson.pendapatan.keterangan,
            status_kepemilikan_rumah: this.props.borrower.borrowerDetail.ojkJson.kepemilikanRumah.keterangan
        });

        // console.log(this.props.borrower.borrowerDetail)
        if (this.props.employee === true) {
            this.setState({
                grup_penjamin: this.props.borrower.borrowerDetail.avalis_grup,
                perusahaan_penjamin: this.props.borrower.borrowerDetail.avalis_perusahaan
            });
        }

        if (this.state.alamat === this.state.alamat_domisili) {
            this.setState({
                samaDomisili: true
            });
        }
        CheckDigisignData(this.state.update_borrower, true); //notif ubah data
    }
    //yg diupdate email, nohp, foto selfie
    authUpdateBorrower = () => {
        if (this.state.update_borrower === true) {
            return false;
        } else {
            return true;
        }
    };

    handleKeyPress = (event) => {
      if (event.key >= '0' && event.key <= '9') {
          event.preventDefault(); 
      }
  };

    //check simpan data
    confirmDataValid() {
        if (this.state.update_borrower === true) {
            return (
                <div>
                    <div className="mt-4 mb-5">
                        <div className="custom-control custom-checkbox filter-input">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={this.handleCheck}
                                    defaultChecked={this.state.checked || ""}
                                    id="agreement"
                                    name="agreement"
                                />
                                Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, dan saya telah melakukan pengecekan atas
                                kebenaran data-data tersebut.
                            </Label>
                        </div>
                    </div>
                    <div className="text-center">
                        {this.props.borrower.isLoadingUpdateDigisign ? (
                            <Button disabled color="primary" onClick={this.handleSaveBorrower} className="btn btn-lg col-12 col-md-6">
                                <span className="loader"></span>
                            </Button>
                        ) : (
                            <Button
                                disabled={this.state.disabled}
                                color="primary"
                                onClick={this.handleSaveBorrower}
                                className="btn btn-lg col-12 col-md-6">
                                Simpan
                            </Button>
                        )}
                    </div>
                </div>
            );
        }
    }
    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };

    formDomisili() {
        if (this.state.samaDomisili === true) {
            return null;
        } else {
            return (
                <div className="col-md-6">
                    <div className="row">
                        <div className="col-12">
                            <InputIcon
                                forLabel={"alamat_domisili"}
                                label={"Alamat Sesuai Domisili"}
                                icon={<Location />}
                                type={"text"}
                                name={"alamat_domisili"}
                                value={this.state.alamat_domisili}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"provinsi_domisili"}
                                label={"Provinsi Domisili"}
                                type={"text"}
                                name={"provinsi_domisili"}
                                value={this.state.provinsi_domisili}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kota_domisili"}
                                label={"Kota / Kabupaten Domisili"}
                                type={"text"}
                                name={"kota_domisili"}
                                value={this.state.kota_domisili}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kecamatan_domisili"}
                                label={"Kecamatan Domisili"}
                                type={"text"}
                                name={"kecamatan_domisili"}
                                value={this.state.kecamatan_domisili}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kelurahan_domisili"}
                                label={"Kelurahan Domisili"}
                                type={"text"}
                                name={"kelurahan_domisili"}
                                value={this.state.kelurahan_domisili}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kode_pos_domisili"}
                                label={"Kodepos Domisili"}
                                type={"text"}
                                name={"kode_pos_domisili"}
                                value={this.state.kode_pos_domisili}
                                readOnly={true}
                            />
                        </div>
                    </div>
                </div>
            );
        }
    }

    form = () => {
        return (
            <div>
                <Form>
                    <h5 className="font-weight-bold pb-4 text-primary">Informasi Pribadi</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"nama_borrower"}
                                label={"Nama Borrower"}
                                icon={<i className="material-icons-outlined">account_circle</i>}
                                type={"text"}
                                name={"nama_borrower"}
                                value={this.state.nama_borrower}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"email"}
                                label={"Email"}
                                icon={<i className="material-icons-outlined">mail</i>}
                                type={"email"}
                                name={"email"}
                                onChange={(e) => this.handleChange(e, "email", "errEmail")}
                                placeholder={"Contoh: example@email.com"}
                                value={this.state.email}
                                error={this.state.errEmail}
                                readOnly={this.authUpdateBorrower()}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_handphone"}
                                label={"Nomor Handphone"}
                                icon={<i className="material-icons-outlined">smartphone</i>}
                                type={"text"}
                                name={"no_handphone"}
                                placeholder={"Contoh: 081xxxxxxxxxx"}
                                value={this.state.no_handphone}
                                readOnly={this.authUpdateBorrower()}
                                onChange={(e) => this.handleChange(e, "number", "errNumber")}
                                error={this.state.errNumber}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"tempat_lahir"}
                                label={"Tempat Lahir"}
                                icon={<Location />}
                                type={"text"}
                                name={"tempat_lahir"}
                                value={this.state.tempat_lahir}
                                readOnly={true}
                                onKeyPress={this.handleKeyPress}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"tgl_lahir"}
                                label={"Tempat Lahir"}
                                icon={<i className="material-icons-outlined">calendar_today</i>}
                                type={"text"}
                                name={"tgl_lahir"}
                                value={this.state.tgl_lahir}
                                readOnly={true}
                                onKeyPress={this.handleKeyPress}

                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_jenis_kelamin"}
                                label={"Jenis Kelamin"}
                                type={"text"}
                                name={"id_jenis_kelamin"}
                                value={this.state.id_jenis_kelamin}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_agama"}
                                label={"Agama"}
                                type={"text"}
                                name={"id_agama"}
                                value={this.state.id_agama}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"status_perkawinan"}
                                label={"Status Perkawinan"}
                                type={"text"}
                                name={"status_perkawinan"}
                                value={this.state.status_perkawinan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_npwp"}
                                label={"Nomor NPWP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                name={"no_npwp"}
                                placeholder={"Contoh: 12.345.678.9-123.000"}
                                value={this.state.no_npwp !== undefined ? this.state.no_npwp : "Tidak ada NPWP"}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_ktp"}
                                label={"Nomor KTP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                name={"no_ktp"}
                                value={this.state.no_ktp}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <ShowFoto label={"Foto KTP"} pc={"Sudah Foto KTP"} onClick={this.togglemodalktp.bind(this)} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <CorrectFotoSelfie
                                data={this.state.update_borrower}
                                onChangeInput={this.toggleOpenCamera.bind(this)}
                                fotoDone={this.state.fotoDone}
                                onChangeShow={this.togglemodalselfie.bind(this)}
                            />
                        </div>
                    </div>
                    <hr />
                    <h5 className="font-weight-bold pb-4 text-primary">Alamat Borrower</h5>
                    <p style={{ fontStyle: "italic" }}>{this.state.samaDomisili === true ? "Alamat KTP sama dengan alamat domisili" : null}</p>
                    <div className="row">
                        <div className={this.state.samaDomisili === true ? "col-md-12" : "col-md-6"}>
                            <div className="row">
                                <div className="col-12">
                                    <InputIcon
                                        forLabel={"alamat"}
                                        label={"Alamat Sesuai KTP"}
                                        icon={<Location />}
                                        type={"text"}
                                        name={"alamat"}
                                        value={this.state.alamat}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Inputs
                                        forLabel={"id_provinsi"}
                                        label={"Provinsi"}
                                        type={"text"}
                                        name={"id_provinsi"}
                                        value={this.state.provinsi}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Inputs
                                        forLabel={"id_kota"}
                                        label={"Kota / Kabupaten"}
                                        type={"text"}
                                        name={"id_kota"}
                                        value={this.state.kota}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Inputs
                                        forLabel={"kelurahan"}
                                        label={"Kelurahan"}
                                        type={"text"}
                                        name={"kelurahan"}
                                        value={this.state.kelurahan}
                                        readOnly={true}
                                    />
                                </div>
                                <div className="col-md-6">
                                    <Inputs
                                        forLabel={"kecamatan"}
                                        label={"Kecamatan"}
                                        type={"text"}
                                        name={"kecamatan"}
                                        value={this.state.kecamatan}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <Inputs
                                        forLabel={"kode_pos"}
                                        label={"Kode Pos"}
                                        type={"text"}
                                        name={"kode_pos"}
                                        value={this.state.kode_pos}
                                        readOnly={true}
                                    />
                                </div>
                            </div>
                        </div>
                        {this.formDomisili()}
                    </div>
                    <hr />
                    <h5 className="font-weight-bold pb-4 text-primary">Pekerjaan Dan Kepemilikan Rumah Borrower</h5>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_pendidikan"}
                                label={"Pendidikan Terakhir"}
                                type={"text"}
                                name={"id_pendidikan"}
                                value={this.state.id_pendidikan}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_pekerjaan"}
                                label={"Pekerjaan"}
                                type={"text"}
                                name={"id_pekerjaan"}
                                value={this.state.id_pekerjaan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_pekerjaan_online"}
                                label={"Pekerjaan Online"}
                                type={"text"}
                                name={"id_pekerjaan_online"}
                                value={this.state.id_pekerjaan_online}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"id_bidang_pekerjaan"}
                                label={"Bidang Pekerjaan"}
                                type={"text"}
                                name={"id_bidang_pekerjaan"}
                                value={this.state.id_bidang_pekerjaan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"pengalaman_kerja"}
                                label={"Pengalaman Kerja"}
                                type={"text"}
                                name={"pengalaman_kerja"}
                                value={this.state.pengalaman_kerja}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"pendapatan"}
                                label={"Pendapatan per bulan"}
                                type={"text"}
                                name={"pendapatan"}
                                value={this.state.pendapatan}
                                readOnly={true}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"status_kepemilikan_rumah"}
                                label={"Status Kepemilikan Rumah"}
                                type={"text"}
                                name={"status_kepemilikan_rumah"}
                                value={this.state.status_kepemilikan_rumah}
                                readOnly={true}
                            />
                        </div>
                    </div>
                    <hr />

                    <ShowBPInformasi
                        data_employee={this.props.employee}
                        data_micro={this.props.micro}
                        grup_penjamin={this.state.grup_penjamin}
                        perusahaan_penjamin={this.state.perusahaan_penjamin}
                        id_sektor={this.state.id_sektor}
                        lokasi_pasar={this.state.lokasi_pasar}
                        deskripsi_usaha={this.state.deskripsi_usaha}
                    />

                    <ShowBPKontakDarurat
                        data_employee={this.props.employee}
                        nama={this.state.nama_kontak_darurat}
                        nohp={this.state.no_handphone_kontak_darurat}
                        alamat={this.state.alamat_kontak_darurat}
                        hubungan={this.state.hubungan_kontak_darurat}
                    />
                    {/* <hr/> */}
                    {this.confirmDataValid()}
                </Form>
            </div>
        );
    };
    render() {
        return (
            <div>
                {this.props.borrower.isLoadingGetID === true ? <LoadingPersonal /> : this.form()}
                {/* Modal show ktp */}
                <ModalFoto isOpen={this.state.modalktp} toggle={this.togglemodalktp.bind(this)} src={this.state.foto_ktp} title={"Foto KTP"} />

                {/* Modal show selfie */}
                <ModalFoto
                    isOpen={this.state.modalselfie}
                    toggle={this.togglemodalselfie.bind(this)}
                    src={this.state.foto_selfie}
                    title={"Foto Selfie"}
                />

                <OpenCamera
                    modalselfieWebcam={this.state.modalselfieWebcam}
                    toggle={this.toggleOpenCamera.bind(this)}
                    dataUri={this.state.re_fotoselfie}
                    handle={(e) => this.handleTakePhotoAnimationDone(e)}
                    fotoLagi={this.fotoLagi}
                    successFoto={this.successFoto}
                    camera={this.state.camera}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        borrower: state.borrower
    };
};
export default connect(mapStateToProps)(DatadiriPersonal);
