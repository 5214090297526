import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import { Withdraw } from "../../../../redux/action/User";
import { connect } from "react-redux";
import { FormGroup, Label, InputGroup, InputGroupAddon, InputGroupText, FormText } from "reactstrap";
import NumberFormat from "react-number-format";
import "../../../../sass/loader.css";
import { encryptedData } from "../../../../library/helper/Crypto";
import { validate } from "../../../../library/validation/validate";
import "./tariksaldo.scss";
import ChooseBank from "./ChooseBank";

class TarikSaldo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nilai_transaksi: "",
            errNominal: "",
            isOpen: "",
            bank: true,
            dipay: false,
            nama_bank: "", //default bank
            nomor_akun_bank: "", //default bank
            nama_pemilik_rekening: "" //default bank
        };
    }

    handleChooseBank = (type) => () => {
        if (type == 'bank') {
            //ini bank
            this.setState({
                dipay: false,
                bank: true
            });
        } else {
            //ini dipay
            this.setState({
                dipay: true,
                bank: false
            });
        }
    };

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value, this.props.saldo) });
        });
    };
    handleClick = async () => {
        if (this.state.errNominal === 0) {
            var nilai_transaksi = this.state.nilai_transaksi.replace(/\D/g, "").replace(/^0+/, "");
            var show_nilaitransaksi = parseInt(nilai_transaksi).toLocaleString("IN");

            Swal.fire({
                title: "Tarik saldo",
                html: this.state.bank
                    ? nilai_transaksi >= 500000000 //untuk penarikan di atas 100 juta
                        ? `Yakin melakukan tarik saldo? <br/> <br/>Saldo yg ditarik : Rp. ${show_nilaitransaksi} <br/> Fee : Rp. 25.000 <br/> Dana yg di dapat : Rp. ${(
                            nilai_transaksi - 25000
                        ).toLocaleString("IN")}`
                        : `Yakin melakukan tarik saldo? <br/> <br/>Saldo yg ditarik : Rp. ${show_nilaitransaksi} <br/> Fee : Rp. 3.500 <br/> Dana yg di dapat : Rp. ${(
                            nilai_transaksi - 3500
                        ).toLocaleString("IN")}`
                    : `Yakin melakukan tarik saldo? <br/> <br/>Saldo yg ditarik : Rp. ${show_nilaitransaksi} <br/> Fee : Rp. 0 <br/> Dana yg di dapat : Rp. ${(
                        nilai_transaksi - 0
                    ).toLocaleString("IN")}`,
                icon: "question",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Yakin"
            })
                .then(async (result) => {
                    if (result.value) {
                        await this.props.dispatch(
                            this.state.bank //ini kalo bank
                                ? Withdraw({
                                    nilai_transaksi: encryptedData(nilai_transaksi),
                                    nama_bank: encryptedData(this.props.nama_bank),
                                    nomor_akun_bank: encryptedData(this.props.no_rek),
                                    nama_pemilik_rekening: encryptedData(this.props.users ? this.props.users : this.props.nama_pengguna),
                                    kode_pengguna: encryptedData(localStorage.masuk)
                                })
                                : Withdraw({
                                    //ini Dipay
                                    nilai_transaksi: encryptedData(nilai_transaksi),
                                    nama_bank: encryptedData("Dipay"),
                                    nomor_akun_bank: encryptedData(this.props.userTlpDipay),
                                    nama_pemilik_rekening: encryptedData(this.props.userNameDipay),
                                    kode_pengguna: encryptedData(localStorage.masuk)
                                })
                        );
                        Swal.fire({
                            title: "Berhasil ",
                            text: "Melakukan penarikan saldo. Silahkan cek di riwayat saldo Anda",
                            icon: "success",
                            button: "ok"
                        }).then(() => {
                            // if (localStorage.tarikDipayError !== undefined || "undefined") {
                            //   localStorage.removeItem("tarikDipayError");
                            // }
                            //untuk hapus localstorage

                            window.location.reload();
                        });
                    }
                })
                .catch((err) => {
                    // localStorage.setItem("tarikDipayError", true); // localstroge disimpan di error untuk dipay aja
                    if (err.response.status === 404) {
                        Swal.fire({
                            title: "Tidak Berhasil",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    } else if (err.response.status === 400) {
                        Swal.fire({
                            title: "Tidak Berhasil",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        } else if (this.props.primary_bank === undefined) {
            Swal.fire({
                title: "Penarikan dana tidak dapat diproses",
                text: "Mohon untuk memasukkan rekening pada menu akun",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            Swal.fire({
                title: "Penarikan dana tidak dapat diproses",
                text: "Mohon untuk periksa kembali nominal yg dimasukkan",
                icon: "warning",
                buttons: "ok"
            });
        }
    };
    render() {
        const BankList = () => {
            if (this.props.primary_bank !== undefined) {
                if (this.props.userNameDipay === "") {
                    return (
                        <ChooseBank
                            primary={true}
                            nama_pemilik_rekening={this.props.users ? this.props.users : this.props.nama_pengguna}
                            nama_bank={this.props.nama_bank}
                            no_rek={this.props.no_rek}
                            value="bank"
                        />
                    );
                } else {
                    if (this.props.loadingUserDipay) {
                        return "loading";
                    } else {
                        return (
                            <div>
                                <ChooseBank
                                    nama_pemilik_rekening={this.props.users ? this.props.users : this.props.nama_pengguna}
                                    nama_bank={this.props.nama_bank}
                                    no_rek={this.props.no_rek}
                                    onChange={this.handleChooseBank('bank')}
                                    value="bank"
                                    defaultChecked={this.state.bank || ""}
                                />

                                <span className="text-red mt-2" style={{ fontSize: '0.7rem' }}><i>*Tarik Saldo melalui <b>Dipay</b> sedang dalam maintenance</i>  </span>
                                {
                                    !this?.props?.dataUsers?.includes(2) ?
                                        <ChooseBank
                                            nama_pemilik_rekening={this.props.userNameDipay}
                                            nama_bank={"dipay"}
                                            primary={false} //simpan di tarikDipayError='true' errornya kalo udah dikirim hapus localstorage
                                            no_rek={this.props.userTlpDipay}
                                            onChange={this.handleChooseBank('dipay')}
                                            pesan={"tidak dapat tersedia"}
                                            value="dipay"
                                            isDisable
                                            defaultChecked={this.state.dipay || ""}
                                        /> : null}
                            </div>
                        );
                    }
                }
            } else {
                return <p>Rekening tidak ada</p>;
            }
        };

        return (
            <Modal
                isOpen={this.props.isOpen}
                toggle={this.props.toggle}
                modalTransition={{ timeout: 100 }}
                className="modal-dialog modal-dialog-centered"
                tabIndex="-1">
                <ModalHeader toggle={this.props.toggle}>
                    <div className="text-center flex-fill">
                        <h5 className="modal-title font-weight-bold">Tarik Saldo</h5>
                        <p className="text-secondary f-14">Pastikan Informasi yang dimasukkan benar</p>
                    </div>
                </ModalHeader>
                <ModalBody>
                    <div className="d-flex align-items-center">
                        <i className="text-primary material-icons-outlined mr-3">account_balance_wallet</i>
                        <span className="font-weight-bold text-secondary">Total Saldo</span>
                        <span className="ml-auto font-weight-bold">Rp {this.props.saldo.toLocaleString("IN")}</span>
                    </div>
                    <div className="form-group pt-4 price-format">
                        <FormGroup>
                            <Label for="nominal" className="font-weight-bold text-secondary">
                                Nominal Penarikan
                            </Label>
                            <InputGroup className="f-18">
                                <InputGroupAddon addonType="prepend" style={{ height: "60px" }}>
                                    <InputGroupText>
                                        <span className="font-weight-bold" style={{ marginLeft: "10px" }}>
                                            Rp
                                        </span>
                                    </InputGroupText>
                                </InputGroupAddon>
                                <NumberFormat
                                    onChange={(e) => this.handleChange(e, "tarikSaldo", "errNominal")}
                                    className="form-control font-weight-bold"
                                    placeholder="0"
                                    autoComplete="off"
                                    name="nilai_transaksi"
                                    thousandSeparator={"."}
                                    decimalSeparator={","}
                                    allowNegative={false}
                                    decimalScale={0}
                                    style={{ height: "60px", color: "black" }}
                                />
                            </InputGroup>
                            <FormText color="danger">{this.state.errNominal === 0 ? null : this.state.errNominal}</FormText>
                        </FormGroup>
                    </div>
                    <div className="mb-4">
                        <p className="font-weight-bold text-secondary f-14">Pilih Rekening Tujuan</p>
                        {BankList()}
                    </div>
                    <p className="f-14 text-secondary pb-4">Nama pemilik rekening untuk penarikan harus sama dengan nama yang terdaftar pada akun.</p>

                    {this.props.user.isLoadingWithdraw === true ? (
                        <button type="submit" disabled className="btn btn-block btn-primary btn-lg" onClick={this.handleClick}>
                            <span className="loader"></span>
                        </button>
                    ) : (
                        <button type="submit" className="btn btn-block btn-primary btn-lg" onClick={this.handleClick}>
                            Tarik Saldo
                        </button>
                    )}
                </ModalBody>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        user: state.user
    };
};
export default connect(mapStateToProps)(TarikSaldo);
