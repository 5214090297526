import React, { Component } from "react";
import { Form, FormGroup, Label, FormText, CustomInput, Input } from "reactstrap";
import { Inputs, InputIcon, SelectOptions, InputFotoKTP, InputFotoSelfie } from "../../../../../library/components/Components";
import Location from "../../../../assets/svg/Location"; //icon svg
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";

class InfoPribadi extends Component {
    showNama() {
        if (localStorage.namaUser === undefined || localStorage.namaUser === "undefined") {
            return localStorage.nama_pengguna;
        } else {
            return localStorage.namaUser;
        }
    }

    showNoHP() {
        if (localStorage.no_handphone === "undefined" || localStorage.no_handphone === undefined) {
            return localStorage.no_hp;
        } else {
            return localStorage.no_handphone;
        }
    }

    handleKeyPress = (event) => {
      if (event.key >= '0' && event.key <= '9') {
          event.preventDefault(); 
      }
  };


    render() {
        console.log(this.props.isNPWPSame)
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Informasi Pribadi</h4>
                <div className="row">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"nama_borrower"}
                            label={"Nama Borrower"}
                            icon={<i className="material-icons-outlined">account_circle</i>}
                            type={"text"}
                            name={"nama_borrower"}
                            placeholder={"Contoh: PT Kaya Raya"}
                            value={this.showNama()}
                            readOnly={localStorage.namaUser || localStorage.nama_pengguna !== "undefined" ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"Email"}
                            label={"Email"}
                            icon={<i className="material-icons-outlined">mail </i>}
                            type={"email"}
                            name={"Email"}
                            placeholder={"Contoh: example@email.com"}
                            value={localStorage.email}
                            readOnly={localStorage.email !== undefined ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"no_handphone"}
                            label={"Nomor Handphone"}
                            icon={<i className="material-icons-outlined">smartphone</i>}
                            type={"text"}
                            name={"no_handphone"}
                            placeholder={"Contoh: 081xxxxxxxxxx"}
                            readOnly={localStorage.no_handphone || localStorage.no_hp ? true : false}
                            value={this.showNoHP()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"tempat_lahir"}
                            label={"Tempat Lahir"}
                            icon={<Location />}
                            type={"text"}
                            name={"tempat_lahir"}
                            onChange={this.props.onChangeTL}
                            value={this.props.data_tempat_lahir ? this.props.data_tempat_lahir : this.props.tempat_lahir}
                            placeholder={"Contoh: Jakarta"}
                            error={this.props.errTempatLahir}
                            readOnly={this.props.data_tempat_lahir ? true : false}
                            onKeyPress={this.handleKeyPress}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="Email">Tanggal Lahir</Label>
                            <DatePicker
                                className="form-control"
                                autoComplete="off"
                                dateFormat="yyyy-MM-dd"
                                selected={this.props.data_tgl_lahir ? this.props.data_tgl_lahir : this.props.tgl_lahir}
                                onChange={this.props.dateChanged}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode    ="select"
                                name="tgl_lahir"
                                maxDate={addYears(new Date(), -17)}
                                withPortal
                                readOnly={this.props.data_tgl_lahir ? true : false}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.data_jk ? this.props.data_jk : this.props.id_jenis_kelamin}
                            forLabel={"id_jenis_kelamin"}
                            label={"Jenis Kelamin"}
                            onChange={this.props.onChange}
                            name={"id_jenis_kelamin"}
                            default={"Pilih Jenis Kelamin"}
                            data={this.props.jenis_kelaminList}
                            readOnly={this.props.data_jk ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.data_agama ? this.props.data_agama : this.props.id_agama}
                            forLabel={"id_agama"}
                            label={"Agama"}
                            onChange={this.props.onChange}
                            name={"id_agama"}
                            default={"Pilih Agama"}
                            data={this.props.agamaList}
                            readOnly={this.props.data_agama ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.data_sk ? this.props.data_sk : this.props.status_perkawinan}
                            forLabel={"status_perkawinan"}
                            label={"Status Perkawinan"}
                            onChange={this.props.onChange}
                            name={"status_perkawinan"}
                            default={"Pilih Status Perkawinan"}
                            data={this.props.status_perkawinanList}
                            readOnly={this.props.data_sk ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"no_ktp"}
                            label={"Nomor KTP"}
                            icon={<i className="material-icons-outlined">credit_card</i>}
                            type={"text"}
                            name={"no_ktp"}
                            onChange={(e) => {
                                if (this.props.isNPWPSame) {
                                    this.props.onChangeNPWP({ target: { name: 'no_npwp', value: e.target.value } })

                                }
                                this.props.onChangeKTP(e)

                            }}
                            placeholder={"Contoh: 12-34-56-78-90-11-2222"}
                            maxLength={"16"}
                            error={this.props.errKtp}
                            value={this.props.data_noktp ? this.props.data_noktp : this.props.no_ktp}
                            readOnly={this.props.data_noktp ? true : false}
                        />
                    </div>
                </div>
                <div className="row pt-2 mb-4">
                    <div className="col-md-6 mb-5 ">
                        <InputIcon
                            forLabel={"no_npwp"}
                            label={"Nomor NPWP"}
                            icon={<i className="material-icons-outlined">credit_card</i>}
                            type={"text"}
                            noMargin={true}
                            name={"no_npwp"}
                            onChange={this.props.onChangeNPWP}
                            placeholder={"Contoh: 12.345.678.9-123.000"}
                            maxLength={"15"}
                            value={this.props.data_nonpwp ? this.props.data_nonpwp : this.props.no_npwp}
                            error={this.props.errNpwp}
                            readOnly={this.props.isNPWPSame}
                        />
                        <div className="custom-control custom-checkbox filter-input">
                            <Label check>
                                <Input
                                    type="checkbox"
                                    onChange={this.props.handleNPWPCheck}
                                    defaultChecked={this.props.isNPWPSame || ""}
                                    id="npwp_check"
                                    name="npwp_check"
                                />
                                <span className="hint-text"> Nomor NPWP sama dengan NIK</span>
                            </Label>
                        </div>
                    </div>
                    <div className="col-md-6">
                        {this.props.data_fotonpwp ? (
                            <Inputs
                                forLabel={"foto_npwp"}
                                label={"Foto NPWP"}
                                type={"text"}
                                name={"foto_npwp"}
                                placeholder={"Sudah foto NPWP"}
                                readOnly={true}
                            />
                        ) : (
                            <FormGroup>
                                <Label for="exampleFile">Foto NPWP (OPTIONAL)</Label>
                                <CustomInput
                                    type="file"
                                    onChange={this.props.handleUploadFile}
                                    name="foto_npwp"
                                    id="exampleFile"
                                    label={this.props.foto_npwp.name !== undefined ? this.props.foto_npwp.name : "Maksimal 10MB"}
                                    accept="image/*"
                                    capture
                                />
                                <FormText color="danger">Jika tidak ada, tidak perlu diisi</FormText>
                            </FormGroup>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {this.props.data_fotoktp ? (
                            <Inputs
                                forLabel={"foto_ktp"}
                                label={"Foto KTP"}
                                type={"text"}
                                name={"foto_ktp"}
                                placeholder={"Sudah foto ktp"}
                                readOnly={true}
                            />
                        ) : (
                            <InputFotoKTP
                                forLabel={"foto_ktp"}
                                label={"Foto KTP"}
                                labelInput={this.props.foto_ktp.name}
                                onChange={this.props.handleUploadFile}
                                name={"foto_ktp"}
                            />
                        )}
                    </div>
                    <div className="col-md-6">
                        {this.props.data_fotoselfie ? (
                            <Inputs
                                forLabel={"foto_selfie"}
                                label={"Foto Selfie"}
                                type={"text"}
                                name={"foto_selfie"}
                                placeholder={"Sudah foto selfie"}
                                readOnly={true}
                            />
                        ) : (
                            <InputFotoSelfie
                                forLabel={"foto_selfie"}
                                label={"Foto Selfie"}
                                onClick={this.props.toggleOpenCamera}
                                fotoDone={this.props.fotoDone}
                            />
                        )}
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="exampleFile">Foto Kartu Keluarga (OPTIONAL)</Label>
                            <CustomInput
                                type="file"
                                onChange={this.props.handleUploadFile}
                                name="foto_kartu_keluarga"
                                id="exampleFile"
                                label={this.props.foto_kartu_keluarga.name !== undefined ? this.props.foto_kartu_keluarga.name : "Maksimal 10MB"}
                                accept="image/*"
                                capture
                            />
                            <FormText color="danger">Jika tidak ada, tidak perlu diisi</FormText>
                        </FormGroup>
                    </div>
                </div>
            </Form>
        );
    }
}
export default InfoPribadi;
