import React, { Component } from "react";
import { Form, FormGroup, Label } from "reactstrap";
import { InputIcon, Inputs, SelectOptions, InputFotoKTP, InputFotoSelfie } from "../../../../../library/components/Components";
import Location from "../../../../assets/svg/Location"; //icon svg
import DatePicker from "react-datepicker";
import { addYears } from "date-fns";

class InfoPIC extends Component {
    showNoHP() {
        if (localStorage.no_handphone === "undefined" || localStorage.no_handphone === undefined) {
            return localStorage.no_hp;
        } else {
            return localStorage.no_handphone;
        }
    }
    handleKeyPress = (event) => {
      if (event.key >= '0' && event.key <= '9') {
          event.preventDefault(); 
      }
  };

    render() {
        return (
            <Form>
                <h4 className="text-center mb-4 d-block d-sm-none font-weight-bold">Info Perwakilan (PIC)</h4>
                <div className="row">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"nama_perwakilan"}
                            label={"PIC Perusahaan"}
                            icon={<i className="material-icons-outlined">account_circle</i>}
                            type={"text"}
                            name={"nama_perwakilan"}
                            onChange={this.props.onChangeName}
                            placeholder={"Contoh: John Doe"}
                            error={this.props.errNama}
                            value={this.props.data_namaperwakilan ? this.props.data_namaperwakilan : this.props.nama_perwakilan}
                            readOnly={this.props.data_namaperwakilan ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"no_handphone"}
                            label={"Nomor Handphone"}
                            icon={<i className="material-icons-outlined">smartphone</i>}
                            type={"text"}
                            name={"no_handphone"}
                            placeholder={"Contoh: 081xxxxxxxxxx"}
                            readOnly={localStorage.no_handphone || localStorage.no_hp ? true : false}
                            value={this.showNoHP()}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <Inputs
                            forLabel={"jabatan_perwakilan"}
                            label={"Jabatan Perwakilan"}
                            onChange={this.props.onChangeJabatan}
                            type={"text"}
                            name={"jabatan_perwakilan"}
                            placeholder={"Contoh: Manager"}
                            error={this.props.errJabatan}
                            value={this.props.data_jabatanperwakilan ? this.props.data_jabatanperwakilan : this.props.jabatan_perwakilan}
                            readOnly={this.props.data_jabatanperwakilan ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <SelectOptions
                            forLabel={"id_bidang_pekerjaan"}
                            label={"Bidang Pekerjaan"}
                            value={this.props.data_bidangpekerjaan ? this.props.data_bidangpekerjaan : this.props.id_bidang_pekerjaan}
                            onChange={this.props.onChange}
                            name={"id_bidang_pekerjaan"}
                            default={"Pilih Bidang Pekerjaan"}
                            data={this.props.bidang_pekerjaanList}
                            readOnly={this.props.data_bidangpekerjaan ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"tempat_lahir"}
                            label={"Tempat Lahir"}
                            icon={<Location />}
                            type={"text"}
                            name={"tempat_lahir"}
                            onChange={this.props.onChangeTL}
                            placeholder={"Contoh: Jakarta"}
                            error={this.props.errTempatLahir}
                            value={this.props.data_tempatlahir ? this.props.data_tempatlahir : this.props.tempat_lahir}
                            readOnly={this.props.data_tempatlahir ? true : false}
                            onKeyPress={this.handleKeyPress}
                        />
                    </div>
                    <div className="col-md-6">
                        <FormGroup>
                            <Label for="ttl">Tanggal Lahir </Label>
                            <br />
                            <DatePicker
                                className="form-control"
                                autoComplete="off"
                                dateFormat="yyyy-MM-dd"
                                selected={this.props.data_tgllahirLender ? this.props.data_tgllahirLender : this.props.tgl_lahir}
                                onChange={this.props.onChangeTGL}
                                peekNextMonth
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                name="tgl_lahir"
                                maxDate={addYears(new Date(), -17)}
                                withPortal
                                readOnly={this.props.data_tgllahirLender ? true : false}
                            />
                        </FormGroup>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <SelectOptions
                            value={this.props.data_jk ? this.props.data_jk : this.props.id_jenis_kelamin}
                            forLabel={"id_jenis_kelamin"}
                            label={"Jenis Kelamin"}
                            onChange={this.props.onChange}
                            name={"id_jenis_kelamin"}
                            default={"Pilih Jenis Kelamin"}
                            data={this.props.jeniskelaminList}
                            readOnly={this.props.data_jk ? true : false}
                        />
                    </div>
                    <div className="col-md-6">
                        <InputIcon
                            forLabel={"no_ktp_perwakilan"}
                            label={"Nomor KTP"}
                            icon={<i className="material-icons-outlined">credit_card</i>}
                            type={"text"}
                            name={"no_ktp_perwakilan"}
                            onChange={this.props.onChangeKTP}
                            placeholder={"Contoh: 12-34-56-78-90-11-2222"}
                            maxLength={"16"}
                            error={this.props.errKtp}
                            value={this.props.data_noktpperwakilan ? this.props.data_noktpperwakilan : this.props.no_ktp_perwakilan}
                            readOnly={this.props.data_noktpperwakilan ? true : false}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {this.props.data_fotoktp ? (
                            <Inputs
                                forLabel={"foto_ktp"}
                                label={"Foto KTP"}
                                type={"text"}
                                name={"foto_ktp"}
                                placeholder={"Sudah foto ktp"}
                                readOnly={true}
                            />
                        ) : (
                            <InputFotoKTP
                                forLabel={"foto_ktp"}
                                label={"Foto KTP Perwakilan"}
                                labelInput={this.props.foto_ktp.name}
                                onChange={this.props.handleUploadFile}
                                name={"foto_ktp"}
                            />
                        )}
                    </div>
                    <div className="col-md-6">
                        {this.props.data_fotoselfie ? (
                            <Inputs
                                forLabel={"foto_selfie"}
                                label={"Foto Selfie"}
                                type={"text"}
                                name={"foto_selfie"}
                                placeholder={"Sudah foto selfie"}
                                readOnly={true}
                            />
                        ) : (
                            <InputFotoSelfie
                                forLabel={"foto_selfie"}
                                label={"Foto Selfie Perwakilan"}
                                onClick={this.props.toggleOpenCamera}
                                fotoDone={this.props.fotoDone}
                            />
                        )}
                    </div>
                </div>
            </Form>
        );
    }
}
export default InfoPIC;
