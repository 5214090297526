import React, { Component } from "react";
import { connect } from "react-redux";
import { LenderId, LenderEdit } from "../../../../redux/action/Lender";
import { Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Input, Button } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../../../../sass/datepicker.css";
import Swal from "sweetalert2";
import { addYears } from "date-fns";
import { LoadingPersonal } from "../../../assets/loading data/LoadingLender";
import {
    InputIcon,
    Inputs,
    ModalFoto,
    ShowFoto,
    InputFotoKTP,
    IconVerifLender,
    OpenCamera,
    InputFotoSelfie,
    SelectOptions,
    SelectEditable
} from "../../../../library/components/Components";
import { validate } from "../../../../library/validation/validate";
import { CheckDigisignData, InfoBrowser, changeCapitalize, imgToFile } from "../../../../library/helper/helper";
import Location from "../../../assets/svg/Location";

class EditPersonalLender extends Component {
    constructor(props) {
        super(props);
        this.state = {
            no_ktp: "",
            no_npwp: "",
            id_kewarganegaraan: "",
            tempat_lahir: "",
            alamat: "",
            id_kota: "",
            id_provinsi: "",
            kode_pos: "",
            id_agama: "",
            status_perkawinan: "",
            id_pekerjaan: "",
            id_bidang_pekerjaan: "",
            pendapatan: "",
            pengalaman_kerja: "",
            id_pendidikan: "",
            nama_lender: "",
            no_handphone: "",
            id_negara_domisili: "",
            jenis_kelamin: "",
            sumber_dana: "",
            foto_ktp: "",
            foto_selfie: "",
            foto_ktp_view: "",
            foto_selfie_view: "",
            file: "",
            defaultValueNegaraDomisili: { value: "", label: "" },
            defaultValueKewarganegaraan: { value: "", label: "" },
            defaultValueProvinsi: { value: "", label: "" },
            defaultValueKota: { value: "", label: "" },
            modal: false,
            modalktp: false,
            modalselfie: false,
            setModal: false,
            popoverOpen: false,
            checked: false,
            disabled: true,
            errKtp: "",
            errNpwp: "",
            errNumber: "",
            errNama: "",
            tgl_lahir: addYears(new Date(), -17),
            update_lender: "",
            update_digisign: "",
            kyc: "",
            loading: true,
            camera: true,
            fotoDone: false,
            modalselfieWebcam: false,
            uploadFotoSelfie: false,
            errEmail: "",
            errNo: "",
            errTempatLahir: "",
            errKecamatan: "",
            errKelurahan: "",
            errKodepos: "",
            errSumber: "",
            isNPWPSame: false,
            nama_bank: "",
            nomor_akun_bank: "",
            nama_pemilik_rekening: "",
            primary_bank: {
                nama_bank: "",
                nomor_akun_bank: "",
                nama_pemilik_rekening: ""
            }
        };
        this.dateChanged = this.dateChanged.bind(this);
        this.fotoLagi = this.fotoLagi.bind(this);
        this.successFoto = this.successFoto.bind(this);
    }
    //js

    handleChange = (e, type, changeError) => {
        let value = e.target.value;
        this.setState({ [e.target.name]: e.target.value }, () => {
            this.setState({ [changeError]: validate(type, value) });
        });
    };

    handleUploadFile = (e) => {
        let file = e.target.files[0];
        this.setState({
            [e.target.name]: file
        });
        //    console.log(e.target.files[0])
    };

    handleSelectSearch = (name, e) => {
        // console.log({[name] : e.value})
        this.setState({ [name]: e.value });
    };
    togglemodalktp() {
        this.setState({
            modalktp: !this.state.modalktp,
            setModal: !this.state.setModal
        });
    }
    togglemodalselfie() {
        this.setState({
            modalselfie: !this.state.modalselfie,
            setModal: !this.state.setModal
        });
    }

    dateChanged(d) {
        this.setState({ tgl_lahir: d });
    }

    //
    //verif akun popover
    toggletkb() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }
    onHover = () => {
        this.setState({
            popoverOpen: true
        });
    };
    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        });
    };
    //end verif

    handleCheck = () => {
        this.setState({ checked: !this.state.checked });
        if (!this.state.checked) {
            this.setState({
                disabled: !this.state.disabled
            });
        } else {
            this.setState({
                disabled: !this.state.disabled
            });
        }
    };

    // foto camera
    toggleOpenCamera() {
        this.setState({
            modalselfieWebcam: !this.state.modalselfieWebcam,
            setModal: !this.state.setModal
        });
    }
    handleTakePhotoAnimationDone(dataUri) {
        this.setState({
            foto_selfie: dataUri,
            camera: false
        });
    }
    fotoLagi() {
        this.setState({
            camera: !this.state.camera
        });
    }
    successFoto = () => {
        this.setState({
            modalselfieWebcam: false,
            setModal: false,
            fotoDone: true
        });
    };
    //

    //function
    handleUpdate = async () => {
        const {
            no_ktp,
            no_npwp,
            id_kewarganegaraan,
            kelurahan,
            kecamatan,
            id_jenis_kelamin,
            tempat_lahir,
            tgl_lahir,
            alamat,
            id_kota,
            id_provinsi,
            kode_pos,
            id_agama,
            status_perkawinan,
            id_pekerjaan,
            id_bidang_pekerjaan,
            id_pendidikan,
            pendapatan,
            pengalaman_kerja,
            id_negara_domisili,
            sumber_dana,
            foto_selfie,
            foto_ktp,
            nama_lender,
            no_handphone,
            nama_bank,
            nama_pemilik_rekening,
            nomor_akun_bank
        } = this.state;

        const formData = new FormData();
        formData.append("no_ktp", no_ktp);
        formData.append("no_npwp", no_npwp);
        formData.append("tempat_lahir", changeCapitalize(tempat_lahir));
        formData.append("tgl_lahir", tgl_lahir);
        formData.append("alamat", alamat);
        formData.append("id_kota", id_kota);
        formData.append("id_provinsi", id_provinsi);
        formData.append("kode_pos", kode_pos);
        formData.append("id_jenis_kelamin", parseInt(id_jenis_kelamin));
        formData.append("id_agama", parseInt(id_agama));
        formData.append("status_perkawinan", parseInt(status_perkawinan));
        formData.append("id_pekerjaan", parseInt(id_pekerjaan));
        formData.append("id_pendidikan", parseInt(id_pendidikan));
        formData.append("id_bidang_pekerjaan", id_bidang_pekerjaan);
        formData.append("pendapatan", pendapatan);
        formData.append("pengalaman_kerja", parseInt(pengalaman_kerja));
        formData.append("sumber_dana", changeCapitalize(sumber_dana));
        formData.append("kelurahan", changeCapitalize(kelurahan));
        formData.append("kecamatan", changeCapitalize(kecamatan));
        if (foto_ktp) formData.append("foto_ktp", foto_ktp);
        formData.append("nama_lender", nama_lender);
        formData.append("no_handphone", no_handphone);
        if (foto_selfie) formData.append("foto_selfie", imgToFile(foto_selfie));
        formData.append("id_kewarganegaraan", parseInt(id_kewarganegaraan));
        formData.append("id_negara_domisili", parseInt(id_negara_domisili));
        formData.append("nama_bank", nama_bank);
        formData.append("nomor_akun_bank", nomor_akun_bank);
        formData.append("nama_pemilik_rekening", nama_pemilik_rekening);

        const updateLender = async () => {
            await this.props
                .dispatch(LenderEdit(this.props.id, formData))
                .then(() => {
                    return Swal.fire({
                        title: "Update Data",
                        text: "Data anda berhasil di rubah",
                        icon: "success",
                        button: "ok"
                    }).then(() => {
                        window.location.reload();
                        window.scrollTo(0, 0);
                    });
                })
                .catch((err) => {
                    // console.log(err.response)
                    if (err.response.status === 400) {
                        Swal.fire({
                            title: "Gagal",
                            text: `${err.response.data.message}`,
                            icon: "warning",
                            button: "ok"
                        });
                    }
                });
        };
        // //cek data input
        if (
            id_jenis_kelamin === 0 ||
            !id_kota ||
            !id_bidang_pekerjaan ||
            id_agama === -1 ||
            status_perkawinan === -1 ||
            id_kewarganegaraan === -1 ||
            id_negara_domisili === -1 ||
            id_provinsi === -1 ||
            id_pendidikan === -1 ||
            id_pekerjaan === -1 ||
            pengalaman_kerja === -1 ||
            pendapatan === -1 ||
            !no_ktp ||
            !kelurahan ||
            !kecamatan ||
            !tempat_lahir ||
            tgl_lahir.toDateString() === new Date().toDateString() ||
            !alamat ||
            !status_perkawinan ||
            !kode_pos ||
            !sumber_dana ||
            !nama_bank ||
            !nomor_akun_bank ||
            !nama_pemilik_rekening
        ) {
            Swal.fire({
                title: "Gagal Daftar",
                text: "Mohon untuk periksa kembali data anda",
                icon: "warning",
                buttons: "ok"
            });
        } else {
            if (!no_npwp) {
                Swal.fire({
                    title: "Data NPWP",
                    text: "Verifikasi akun tanpa data NPWP akan di kenakan pajak Bunga 30% (lebih besar dari mengisi data 15%) Pada setiap Pendanaan",
                    icon: "warning",
                    buttons: "ok",
                    showCancelButton: true,
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "Mengerti",
                    cancelButtonText: "Edit Data"
                }).then((result) => {
                    if (result.value) {
                        updateLender();
                    }
                });
            } else {
                updateLender();
            }
            // console.log('TRUEEEE')
        }
    };

    validFotoKTP = () => {
        return (
            <>
                <InputFotoKTP forLabel={"Upload Foto KTP"} label={"Upload Ulang Foto KTP"} onChange={this.handleUploadFile} name={"foto_ktp"} />
                <ShowFoto label={"Foto KTP"} pc={"Sudah Foto KTP"} onClick={this.togglemodalktp.bind(this)} />
            </>
        );
    };
    validFotoSelfie = () => {
        return (
            <>
                <InputFotoSelfie
                    forLabel={"foto_selfie"}
                    label={"Upload Ulang Foto Selfie"}
                    onClick={this.toggleOpenCamera.bind(this)}
                    fotoDone={this.state.fotoDone}
                />
                <ShowFoto label={"Foto Selfie"} pc={"Sudah Foto Selfie"} onClick={this.togglemodalselfie.bind(this)} />
            </>
        );
    };
    authTglLahir() {
        return (
            <DatePicker
                className="form-control"
                autoComplete="off"
                dateFormat="yyyy-MM-dd"
                selected={this.state.tgl_lahir}
                onChange={this.dateChanged}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                name="tgl_lahir"
                minDate={addYears(new Date(), -70)}
                maxDate={addYears(new Date(), -17)}
                withPortal
            />
        );
    }
    handleNPWPCheck = () => {
        this.setState({ isNPWPSame: !this.state.isNPWPSame }, () => {
            if (this.state.isNPWPSame) {
                this.setState({
                    no_npwp: this.state.no_ktp
                });
            }
            else {
                this.setState({
                    no_npwp: this.state.no_npwp
                });
            }
        });

    };
    readOnly() {
        if (this.state.update_lender === true) {
            return true;
        } else {
            return false;
        }
    }
    authReadonlyDigisign() {
        if (this.state.update_digisign === true) {
            return false;
        } else {
            return true;
        }
    }

    confirmDataValid() {
        return (
            <div>
                {/* <div className="mt-4 mb-5">
          <div className="custom-control custom-checkbox filter-input" style={{ zIndex: 0 }}>
            <input
              type="checkbox"
              className="custom-control-input"
              id="agreement"
              name="agreement"
              onChange={this.handleCheck}
              defaultChecked={this.state.checked || ""}
            />
            <label className="custom-control-label" htmlFor="agreement">
              Saya menyatakan bahwa data-data yang telah saya isi diatas adalah valid, saya telah melakukan pengecekan
              atas kebenaran data-data tersebut, dan saya telah membaca ketentuan privacy dan cookies .
            </label>
          </div>
        </div> */}
                <div className="text-center">
                    <Button
                        // disabled={this.state.disabled}
                        color="primary"
                        onClick={this.handleUpdate}
                        className="btn btn-lg col-12 col-md-6">
                        {this.props.lender.isLoadingEditLender ? <span className="loader"></span> : "Ubah Data"}
                    </Button>
                </div>
            </div>
        );
        // }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        await this.props.dispatch(LenderId(this.props.id));
        if (this.props.lender.lenderDetail.pengguna.bank.length > 0) {
            this.setState({
                primary_bank: this.props.lender.lenderDetail.pengguna.bank.find((el) => el.bank_utama !== undefined)
            });
            this.setState({
                nama_bank: this.state.primary_bank.nama_bank,
                nomor_akun_bank: this.state.primary_bank.nomor_akun_bank,
                nama_pemilik_rekening: this.state.primary_bank.nama_pemilik_rekening
            });
        }
        this.setState({
            _id: this.props.lender.lenderDetail._id,
            nama_lender: this.props.lender.lenderDetail.nama_lender,
            email: this.props.lender.lenderDetail.pengguna.email,
            no_handphone: this.props.lender.lenderDetail.pengguna.no_handphone,
            no_ktp: this.props.lender.lenderDetail.no_ktp,
            no_npwp: this.props.lender.lenderDetail.no_npwp,
            tempat_lahir: this.props.lender.lenderDetail.tempat_lahir,
            tgl_lahirLender: this.props.lender.lenderDetail.tgl_lahir,
            alamat: this.props.lender.lenderDetail.alamat,
            kode_pos: this.props.lender.lenderDetail.kode_pos,
            sumber_dana: this.props.lender.lenderDetail.sumber_dana,
            foto_ktp_view: this.props.lender.lenderDetail.foto_ktp,
            foto_selfie_view: this.props.lender.lenderDetail.foto_selfie,
            bank: this.props.lender.lenderDetail.pengguna.bank,
            kyc: this.props.lender.lenderDetail.admin_kyc,
            update_lender: this.props.lender.lenderDetail.update_lender,
            update_digisign: this.props.lender.lenderDetail.update_digisign,
            kelurahan: this.props.lender.lenderDetail.kelurahan,
            kecamatan: this.props.lender.lenderDetail.kecamatan,
            tgl_lahir: new Date(this.props.lender.lenderDetail.tgl_lahir)
        });
        this.setState({
            kota: this.props.lender.lenderDetail.ojkJson.kota.keterangan,
            negaraDomisili: this.props.lender.lenderDetail.ojkJson.negaraDomisili.keterangan,
            kwn: this.props.lender.lenderDetail.ojkJson.kewarganegaraan.keterangan,
            provinsi: this.props.lender.lenderDetail.ojkJson.provinsi.keterangan,
            id_agama: this.props.lender.lenderDetail.ojkJson.agama.kode,
            id_bidang_pekerjaan: this.props.lender.lenderDetail.ojkJson.bidangPekerjaan.kode,
            id_kota: this.props.lender.lenderDetail.ojkJson.kota.kode,
            id_negara_domisili: this.props.lender.lenderDetail.ojkJson.negaraDomisili.kode,
            id_kewarganegaraan: this.props.lender.lenderDetail.ojkJson.kewarganegaraan.kode,
            id_pekerjaan: this.props.lender.lenderDetail.ojkJson.pekerjaan.kode,
            id_provinsi: this.props.lender.lenderDetail.ojkJson.provinsi.kode,
            pendapatan: this.props.lender.lenderDetail.ojkJson.pendapatan.kode,
            pengalaman_kerja: this.props.lender.lenderDetail.ojkJson.pengalamanKerja.kode,
            status_perkawinan: this.props.lender.lenderDetail.ojkJson.statusPerkawinan.kode,
            id_pendidikan: this.props.lender.lenderDetail.ojkJson.pendidikan.kode,
            id_jenis_kelamin: this.props.lender.lenderDetail.ojkJson.jenisKelamin.kode,
            defaultValueNegaraDomisili: {
                value: this.props.lender.lenderDetail.ojkJson.negaraDomisili.kode,
                label: this.props.lender.lenderDetail.ojkJson.negaraDomisili.keterangan
            },
            defaultValueKewarganegaraan: {
                value: this.props.lender.lenderDetail.ojkJson.kewarganegaraan.kode,
                label: this.props.lender.lenderDetail.ojkJson.kewarganegaraan.keterangan
            },
            defaultValueProvinsi: {
                value: this.props.lender.lenderDetail.ojkJson.provinsi.kode,
                label: this.props.lender.lenderDetail.ojkJson.provinsi.keterangan
            },
            defaultValueKota: {
                value: this.props.lender.lenderDetail.ojkJson.kota.kode,
                label: this.props.lender.lenderDetail.ojkJson.kota.keterangan
            }
        });

        // console.log(this.props.lender.lenderDetail.ojkJson);
        //jika data salah dan camera web lewat browser
        if (this.state.update_lender !== true) {
            InfoBrowser();
        } else {
            CheckDigisignData(this.state.update_digisign, true);
        }
    }
    //
    //verif icon
    authVerifikasiIcon = () => {
        if (this.state.kyc === true) {
            return (
                <IconVerifLender
                    onMouseEnter={this.onHover}
                    onMouseLeave={this.onHoverLeave}
                    icon={
                        <i className="material-icons text-success" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                            check_circle
                        </i>
                    }
                    isOpen={this.state.popoverOpen}
                    toggle={this.togglePopover.bind(this)}
                    info={"Anda sudah terverifikasi"}
                />
            );
        } else {
            if (this.state.update_lender === true) {
                return (
                    <IconVerifLender
                        onMouseEnter={this.onHover}
                        onMouseLeave={this.onHoverLeave}
                        icon={
                            <i className="material-icons text-danger" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                                update
                            </i>
                        }
                        isOpen={this.state.popoverOpen}
                        toggle={this.togglePopover.bind(this)}
                        info={"Tahap verifikasi data"}
                    />
                );
            } else {
                return (
                    <IconVerifLender
                        onMouseEnter={this.onHover}
                        onMouseLeave={this.onHoverLeave}
                        icon={
                            <i className="material-icons text-danger" style={{ paddingTop: "10px", paddingLeft: "5px" }}>
                                cancel
                            </i>
                        }
                        isOpen={this.state.popoverOpen}
                        toggle={this.togglePopover.bind(this)}
                        info={"Data Anda belum terisi"}
                    />
                );
            }
        }
    };
    handleKeyPress = (event) => {
      if (event.key >= '0' && event.key <= '9') {
          event.preventDefault(); 
      }
  };

    togglePopover() {
        this.setState({
            popoverOpen: !this.state.popoverOpen
        });
    }
    onHover = () => {
        this.setState({
            popoverOpen: true
        });
    };
    onHoverLeave = () => {
        this.setState({
            popoverOpen: false
        });
    };
    form = () => {
        return (
            <div className="card-body">
                <Form>
                    <div className="row">
                        <div className="col-md-6">
                            <FormGroup>
                                <Label htmlFor="nama-lengkap">Nama Lengkap (Sesuai KTP)</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="material-icons-outlined">account_circle</i>
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        name="nama_lender"
                                        type="text"
                                        placeholder="Contoh: John Doe"
                                        value={this.state.nama_lender || ""}
                                        readOnly
                                    />
                                    {/* {this.authVerifikasiIcon()} */}
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"email"}
                                label={"Email"}
                                icon={<i className="material-icons-outlined">mail </i>}
                                type={"text"}
                                name={"email"}
                                value={this.state.email}
                                readOnly={this.authReadonlyDigisign()}
                                onChange={(e) => this.handleChange(e, "string", "errEmail")}
                                error={this.state.errEmail}
                            />
                        </div>
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_handphone"}
                                label={"No Handphone"}
                                icon={<i className="material-icons-outlined">mail </i>}
                                type={"text"}
                                name={"no_handphone"}
                                value={this.state.no_handphone}
                                readOnly={this.authReadonlyDigisign()}
                                onChange={(e) => this.handleChange(e, "number", "errNo")}
                                error={this.state.errNo}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"no_ktp"}
                                label={"Nomor KTP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                              
                                name={"no_ktp"}
                                onChange={(e) => {
                                    if (this.state.isNPWPSame) {
                                        this.handleChange({ target: { name: 'no_npwp', value: e.target.value } }, "number", "errNpwp")

                                    }
                                    this.handleChange(e, "number", "errKtp")

                                }}
                                placeholder={"Contoh: 12-34-56-78-90-11-2222"}
                                maxLength={"16"}
                                error={this.state.errKtp}
                                readOnly={this.readOnly()}
                                value={this.state.no_ktp}
                            />
                        </div>
                        <div className="col-md-6 mb-5">
                            <InputIcon
                                forLabel={"no_npwp"}
                                label={"Nomor NPWP"}
                                icon={<i className="material-icons-outlined">credit_card</i>}
                                type={"text"}
                                noMargin={true}
                                name={"no_npwp"}
                                onChange={(e) => this.handleChange(e, "number", "errNpwp")}
                                placeholder={"Contoh: 12.345.678.9-123.000"}
                                maxLength={"16"}
                                error={this.state.errNpwp}
                                readOnly={this.readOnly() || this.state.isNPWPSame}
                                value={this.state.no_npwp}
                            />
                            <div className="custom-control custom-checkbox filter-input">
                                <Label check>
                                    <Input
                                        type="checkbox"
                                        onChange={this.handleNPWPCheck}
                                        defaultChecked={this.state.isNPWPSame || ""}
                                        id="npwp_check"
                                        name="npwp_check"
                                    />
                                    <span className="hint-text"> Nomor NPWP sama dengan NIK</span>
                                </Label>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">{this.validFotoKTP()}</div>
                        <div className="col-md-6">{this.validFotoSelfie()}</div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <InputIcon
                                forLabel={"tempat_lahir"}
                                label={"Tempat Lahir"}
                                icon={<Location />}
                                type={"text"}
                                name={"tempat_lahir"}
                                onChange={(e) => this.handleChange(e, "string", "errTempatLahir")}
                                placeholder={"Contoh: Jakarta"}
                                error={this.state.errTempatLahir}
                                value={this.state.tempat_lahir}
                                readOnly={this.readOnly()}
                                onKeyPress={this.handleKeyPress}
                            />
                        </div>
                        <div className="col-md-6">
                            <FormGroup>
                                <Label htmlFor="tanggal_lahir">Tanggal Lahir</Label>
                                {this.authTglLahir()}
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_jenis_kelamin"}
                                label={"Jenis Kelamin"}
                                onChange={(e) => this.handleChange(e)}
                                name={"id_jenis_kelamin"}
                                default={"Pilih Jenis Kelamin"}
                                data={this.props.jeniskelaminList}
                                value={this.state.id_jenis_kelamin}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_agama"}
                                label={"Agama"}
                                name={"id_agama"}
                                value={this.state.id_agama}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Agama"}
                                data={this.props.agamaList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"status_perkawinan"}
                                label={"Status Perkawinan"}
                                name={"status_perkawinan"}
                                value={this.state.status_perkawinan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Status Perkawinan"}
                                data={this.props.status_perkawinanList}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <SelectEditable
                                forLabel={"id_negara_domisili"}
                                label={"Negara Domisili"}
                                name={"id_negara_domisili"}
                                onChange={this.handleSelectSearch.bind(null, "id_negara_domisili")}
                                data={this.props.negara_domisiliList}
                                defaultValue={this.state.defaultValueNegaraDomisili}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectEditable
                                forLabel={"id_kewarganegaraan"}
                                label={"Kewarganegaraan"}
                                name={"id_kewarganegaraan"}
                                value={this.state.id_kewarganegaraan}
                                onChange={this.handleSelectSearch.bind(null, "id_kewarganegaraan")}
                                data={this.props.negara_domisiliList}
                                defaultValue={this.state.defaultValueKewarganegaraan}
                            />
                        </div>
                        <div className="col-12">
                            <InputIcon
                                forLabel={"alamat"}
                                label={"Alamat Sesuai KTP"}
                                icon={<Location />}
                                type={"text"}
                                name={"alamat"}
                                onChange={(e) => this.handleChange(e)}
                                placeholder={"Contoh: Jakarta"}
                                value={this.state.alamat}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectEditable
                                forLabel={"id_provinsi"}
                                label={"Provinsi"}
                                name={"id_provinsi"}
                                value={this.state.id_provinsi}
                                onChange={this.handleSelectSearch.bind(null, "id_provinsi")}
                                data={this.props.provinsiList}
                                defaultValue={this.state.defaultValueProvinsi}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectEditable
                                forLabel={"id_kota"}
                                label={"Kota/Kabupaten"}
                                name={"id_kota"}
                                value={this.state.id_kota}
                                onChange={this.handleSelectSearch.bind(null, "id_kota")}
                                data={this.props.kotaList}
                                defaultValue={this.state.defaultValueKota}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kecamatan"}
                                label={"Kecamatan"}
                                onChange={(e) => this.handleChange(e, "string", "errkecamatan")}
                                type={"text"}
                                name={"kecamatan"}
                                placeholder={"Contoh: Cengkareng Barat"}
                                error={this.state.errkecamatan}
                                value={this.state.kecamatan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kelurahan"}
                                label={"Kelurahan"}
                                onChange={(e) => this.handleChange(e, "string", "errKelurahan")}
                                type={"text"}
                                name={"kelurahan"}
                                placeholder={"Contoh: Cengkareng"}
                                error={this.state.errKelurahan}
                                value={this.state.kelurahan}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"kode_pos"}
                                label={"Kodepos"}
                                onChange={(e) => this.handleChange(e, "number", "errKodepos")}
                                type={"text"}
                                name={"kode_pos"}
                                placeholder={"Contoh : 12345"}
                                error={this.state.errKodepos}
                                maxLength={"5"}
                                readOnly={this.readOnly()}
                                value={this.state.kode_pos}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_pendidikan"}
                                label={"Pendidikan Terakhir"}
                                name={"id_pendidikan"}
                                value={this.state.id_pendidikan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Pendidikan Terakhir"}
                                data={this.props.pendidikanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_pekerjaan"}
                                label={"Pekerjaan"}
                                name={"id_pekerjaan"}
                                value={this.state.id_pekerjaan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Pekerjaan"}
                                data={this.props.pekerjaanList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"id_bidang_pekerjaan"}
                                label={"Bidang Pekerjaan"}
                                name={"id_bidang_pekerjaan"}
                                value={this.state.id_bidang_pekerjaan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Bidang Pekerjaan"}
                                data={this.props.bidang_pekerjaanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"pengalaman_kerja"}
                                label={"Pengalaman Kerja"}
                                name={"pengalaman_kerja"}
                                value={this.state.pengalaman_kerja}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih Pengalaman Kerja"}
                                data={this.props.pengalamanList}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectOptions
                                forLabel={"pendapatan"}
                                label={"Pendapatan Per Bulan"}
                                name={"pendapatan"}
                                value={this.state.pendapatan}
                                onChange={(e) => this.handleChange(e)}
                                default={"Pilih pendapatan"}
                                data={this.props.pendapatanList}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"sumber_dana"}
                                label={"Sumber Dana"}
                                onChange={(e) => this.handleChange(e, "string", "errSumber")}
                                type={"text"}
                                name={"sumber_dana"}
                                placeholder={"Contoh : Penghasilan"}
                                error={this.state.errSumber}
                                value={this.state.sumber_dana}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"nama_pemilik_rekening"}
                                label={"Nama Pemilik Rekening"}
                                onChange={(e) => this.handleChange(e, "string", "errNama")}
                                type={"text"}
                                name={"nama_pemilik_rekening"}
                                placeholder={"Nama Pemilik Rekening"}
                                error={this.state.errNama}
                                value={this.state.nama_pemilik_rekening}
                                readOnly={this.readOnly()}
                            />
                        </div>
                        <div className="col-md-6">
                            <Inputs
                                forLabel={"nomor_akun_bank"}
                                label={"Nomor Rekening"}
                                onChange={(e) => this.handleChange(e, "number", "errNumber")}
                                type={"text"}
                                name="nomor_akun_bank"
                                placeholder="Masukkan Nomor Rekening"
                                error={this.state.errNumber}
                                value={this.state.nomor_akun_bank}
                                readOnly={this.readOnly()}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6">
                            <SelectEditable
                                forLabel={"nama_bank"}
                                label={"Nama Bank"}
                                name={"nama_bank"}
                                value={this.state.nama_bank}
                                onChange={(e) =>
                                    this.setState({
                                        nama_bank: e.label
                                    })
                                }
                                data={this.props.bank}
                                defaultValue={{
                                    label: this.state.nama_bank,
                                    value: this.state.nama_bank
                                }}
                            />
                        </div>
                    </div>

                    {this.confirmDataValid()}
                </Form>
            </div>
        );
    };
    render() {
        return (
            <div>
                {this.props.lender.isLoadingLender ? <LoadingPersonal /> : this.form()}
                {/* Modal show ktp */}
                <ModalFoto isOpen={this.state.modalktp} toggle={this.togglemodalktp.bind(this)} src={this.state.foto_ktp_view} title={"Foto KTP"} />

                {/* Modal show selfie */}
                <ModalFoto
                    isOpen={this.state.modalselfie}
                    toggle={this.togglemodalselfie.bind(this)}
                    src={this.state.foto_selfie_view}
                    title={"Foto Selfie"}
                />

                <OpenCamera
                    modalselfieWebcam={this.state.modalselfieWebcam}
                    toggle={this.toggleOpenCamera.bind(this)}
                    dataUri={this.state.foto_selfie}
                    handle={(e) => this.handleTakePhotoAnimationDone(e)}
                    fotoLagi={this.fotoLagi}
                    successFoto={this.successFoto}
                    camera={this.state.camera}
                />
            </div>
        );
    }
}
const mapStateToProps = (state) => {
    return {
        lender: state.lender
    };
};
export default connect(mapStateToProps)(EditPersonalLender);
